// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PeriodSelector from "../../../../components/period_selector/PeriodSelector";
import ChartMetrics from "./ChartMetrics";
import styles from "./metrics.module.css";
import { selectAllSynthetic } from "../../selectors";
import Button from "../../../../components/button/Button";
import buttonStyle from "../../../../components/button/button.module.css";

const MetricsPage = () => {
  const [metrics, setMetrics] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { synId } = useParams();
  const synthetics = useSelector(selectAllSynthetic);
  const [synthetic, setSynthetic] = useState();
  const [hoveredButton, setHoveredButton] = useState(null);
  const [activeButton, setActiveButton] = useState("button3");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (synthetics.length > 0) {
      const foundSynthetic = synthetics.find((syn) => syn.id === parseInt(synId, 10));
      if (foundSynthetic) {
        setSynthetic(foundSynthetic);
      } else {
        navigate("/account/mySynthetic");
      }
      setLoading(false);
    } else {
      setLoading(false);
      navigate(`/account/mySynthetic/${parseInt(synId, 10)}`);
    }
  }, [synthetics, synId, navigate]);

  const handleMouseEnter = (buttonId) => {
    setHoveredButton(buttonId);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  const handleClick = (path, buttonId) => {
    navigate(path);
    setActiveButton(buttonId);
  };

  const loadMetrics = useCallback(async () => {
    if (!synthetic || !synthetic.syntheticUuid) {
      setError("Synthetic object not found or missing UUID");
      return;
    }
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(`${import.meta.env.VITE_METRICS_API_URL}/getMetrics`, {
        syntheticUuid: synthetic.syntheticUuid,
        startDate: format(startDate, "yyyy-MM-dd'T'00:00:00"),
        endDate: format(endDate, "yyyy-MM-dd'T'23:59:59"),
      });

      setMetrics(response.data);
    } catch (fetchError) {
      setError("Failed to load metrics");
    } finally {
      setLoading(false);
    }
  }, [synthetic, startDate, endDate]);

  useEffect(() => {
    loadMetrics();
  }, [loadMetrics]);

  const handlePeriodChange = (period) => {
    const now = new Date();
    let start;

    switch (period) {
      case "today":
        start = now;
        break;
      case "week":
        start = new Date(now.setDate(now.getDate() - 7));
        break;
      case "month":
        start = new Date(now.setDate(now.getDate() - 30));
        break;
      case "year":
        start = new Date(now.setDate(now.getDate() - 365));
        break;
      default:
        start = now;
    }

    setStartDate(start);
    setEndDate(new Date());
  };

  return (
    <div className={styles.mainBox}>
      <div className={styles.leftBarContainer}>
        <div className={styles.backContainer}>
          <img src="/circle.webp" alt="buttonBack" />
        </div>
        <div>
          <button
            className={`${styles.buttonBack} ${activeButton === "buttonBack" ? styles.buttonBackPressed : ""}`}
            type="button"
            onClick={() => handleClick("/account/mySynthetic", "buttonBack")}
            id="backToMyAccountButton"
          >
            <div className={styles.buttonBackContainer}>
              <img src="/arrow-right.webp" alt="arrow" className={styles.arrowIcon} />
              <div className={styles.buttonBackTextContainer}>
                <span>Back</span>
              </div>
            </div>
          </button>
        </div>
        <div>
          <button
            className={`${styles.button1} ${activeButton === "button1" || hoveredButton === "button1" ? styles.buttonPressed : ""}`}
            type="button"
            onClick={() => handleClick(`/account/mySynthetic/${synId}`, "button1")}
            onMouseEnter={() => handleMouseEnter("button1")}
            onMouseLeave={handleMouseLeave}
            id="generalButton"
          >
            <div className={styles.buttonContainer}>
              <div className={styles.textContainer}>
                <span>General</span>
              </div>
              {hoveredButton === "button1" && (
                <div className={styles.imageContainer}>
                  <img src="/circle.webp" alt="button1" />
                </div>
              )}
            </div>
          </button>
          <button
            className={`${styles.button2} ${activeButton === "button2" || hoveredButton === "button2" ? styles.buttonPressed : ""}`}
            type="button"
            onClick={() => handleClick(`/account/mySynthetic/editStyle/${synId}`, "button2")}
            onMouseEnter={() => handleMouseEnter("button3")}
            onMouseLeave={handleMouseLeave}
            id="editStyleButton"
          >
            <div className={styles.buttonContainer}>
              <div className={styles.textContainer}>
                <span>Edit style</span>
              </div>
              {hoveredButton === "button2" && (
                <div className={styles.imageContainer}>
                  <img src="/circle.webp" alt="button2" />
                </div>
              )}
            </div>
          </button>
          <button
            className={`${styles.button3} ${activeButton === "button3" || hoveredButton === "button3" ? styles.buttonPressed : ""}`}
            type="button"
            onClick={() => handleClick(`/account/mySynthetic/metrics/${synId}`, "button3")}
            onMouseEnter={() => handleMouseEnter("button3")}
            onMouseLeave={handleMouseLeave}
            id="metricsDataButton"
          >
            <div className={styles.buttonContainer}>
              <div className={styles.textContainer}>
                <span>Statistics</span>
              </div>
              {hoveredButton === "button3" && (
                <div className={styles.imageContainer}>
                  <img src="/circle.webp" alt="button3" />
                </div>
              )}
            </div>
          </button>
        </div>
      </div>
      <div className={styles.avatar}>
        {synthetic?.avatar ? (
          <img src={`data:image/*;base64,${synthetic.avatar}`} alt={synthetic.name} />
        ) : (
          <p>No avatar</p>
        )}
      </div>

      <div>
        <h2>Statistics for {synthetic?.name}</h2>

        <PeriodSelector onSelect={handlePeriodChange} />

        <div className={styles.dateInputContainer}>
          <label>Start date: </label>
          <input
            type="date"
            value={format(startDate, "yyyy-MM-dd")}
            onChange={(e) => setStartDate(new Date(e.target.value))}
          />

          <label>End date: </label>
          <input
            type="date"
            value={format(endDate, "yyyy-MM-dd")}
            onChange={(e) => setEndDate(new Date(e.target.value))}
          />

          <Button className={buttonStyle.buttonApply} buttonName="Load statistics" onClick={loadMetrics} />
        </div>

        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}

        {!loading && !error && metrics.length > 0 && <ChartMetrics metrics={metrics} />}

        {!loading && metrics.length === 0 && <p>No metrics found for this period</p>}
      </div>
    </div>
  );
};

export default MetricsPage;
