import React, { useCallback, useEffect, useState } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import { useDispatch, useSelector } from "react-redux";
import styles from "./addNewPartner.module.css";
import PreLoading from "../../../components/pre_loading/PreLoading";
import * as selector from "../../user_synthetic/selectors";
import { uploadSharedAvatar, uploadNewPartner } from "../../user_synthetic/userSyntheticSlice";
import AccountNavbar from "../../account_layout/AccountNavbar";

function AddNewPartner() {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(null);
  const [activeBtnPicture, setActiveBtnPicture] = useState(null);
  const [imageData, setImageData] = useState(null);
  const isLoadingUploadAvatar = useSelector(selector.selectIsLoadingUploadAvatar);
  const [newPartner, setNewPartner] = useState({
    name: "",
    link: "",
    first_description: "",
    second_description: "",
    third_description: "",
  });
  // console.log(imageData);

  const handleEdit = (event) => {
    const { name: key, value } = event.target;
    setNewPartner((prevNewPartner) => ({
      ...prevNewPartner,
      [key]: value,
    }));
  };

  const handleClickBtn = (currSection, buttonId) => {
    if (currSection === "pictureSection") {
      setActiveBtnPicture(buttonId);
    }
  };

  const handleOpenModal = (modal) => {
    setOpenModal(modal);
  };

  const handleSubmitUpload = useCallback(async () => {
    if (!imageData) {
      console.error("No file selected");
      return;
    }
    console.log("here")
    const dispatchResult = await dispatch(uploadNewPartner({ newPartner, imageData }));
    if (uploadNewPartner.fulfilled.match(dispatchResult)) {
      setOpenModal(null);
      setActiveBtnPicture(null);
    }
    if (uploadSharedAvatar.rejected.match(dispatchResult)) {
      console.error("upload error");
    }
  }, [dispatch, imageData, newPartner]);

  return (
    <div>
      <AccountNavbar />
      <div>
        <div className={styles.text}>Add image for partner</div>
        <button
          className={`${styles.btn} ${activeBtnPicture === "btnPictureUpload" ? styles.activeBtnByCreate : ""}`}
          id="btnPictureUpload"
          type="button"
          onClick={() => {
            handleClickBtn("pictureSection", "btnPictureUpload");
            handleOpenModal("uploadModal");
          }}
        >
          <UploadIcon />
          Upload new avatar
        </button>
      </div>
      {openModal === "uploadModal" && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            {isLoadingUploadAvatar ? (
              <PreLoading />
            ) : (
              <>
                <form className={styles.formUpload} method="POST" encType="multipart/form-data">
                  <div className={styles.inputContainer}>
                    <span className={styles.nameText}>Partner's Name: </span>
                    <input
                      className={styles.nameInput}
                      type="text"
                      name="name"
                      id="nameInputSynthetic"
                      onChange={(e) => {
                        handleEdit(e);
                      }}
                    />
                    <span className={styles.nameText}>First description: </span>
                    <input
                      className={styles.nameInput}
                      type="text"
                      name="first_description"
                      id="nameInputSynthetic"
                      onChange={(e) => {
                        handleEdit(e);
                      }}
                    />
                    <span className={styles.nameText}>Second description: </span>
                    <input
                      className={styles.nameInput}
                      type="text"
                      name="second_description"
                      id="nameInputSynthetic"
                      onChange={(e) => {
                        handleEdit(e);
                      }}
                    />
                    <span className={styles.nameText}>Third description: </span>
                    <input
                      className={styles.nameInput}
                      type="text"
                      name="third_description"
                      id="nameInputSynthetic"
                      onChange={(e) => {
                        handleEdit(e);
                      }}
                    />
                    <span className={styles.nameText}>Link: </span>
                    <input
                      className={styles.nameInput}
                      type="text"
                      name="link"
                      id="nameInputSynthetic"
                      onChange={(e) => {
                        handleEdit(e);
                      }}
                    />
                  </div>
                  <input
                    className={styles.fileInput}
                    type="file"
                    id="fileInput"
                    name="file"
                    accept="image/*"
                    onChange={(e) => setImageData(e.target.files[0])}
                  />
                  {imageData === null ? (
                    <button className={`${styles.btn} ${styles.noActiveBtn}`} type="button" id="submitUploadBtn">
                      Upload
                    </button>
                  ) : (
                    <button
                      className={`${styles.btn} ${styles.submitUploadBtn} `}
                      type="button"
                      id="submitUploadBtn"
                      onClick={handleSubmitUpload}
                    >
                      Upload
                    </button>
                  )}
                </form>
                <button
                  className={`${styles.btn} ${styles.modalUploadCancelBtn}`}
                  type="button"
                  id="modalUploadCancelBtn"
                  onClick={() => {
                    setOpenModal(null);
                    setActiveBtnPicture(null);
                    setImageData(null);
                  }}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default AddNewPartner;
