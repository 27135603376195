// eslint-disable-next-line no-unused-vars
import React, { useCallback } from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import styles from "../../../createSynthetic.module.css";
import AvatarCropper from "../../../../avatar_cropper/AvatarCropper";
import { uploadAvatar } from "../../../../userSyntheticSlice";
import localStyle from "./uploadAvatar.module.css";

function UploadAvatar({
  setOpenModal,
  setActiveBtnPicture,
  setCheckDonePicture,
  setOpenErrorWindow,
  setErrorMessage,
  setSelectedAvatar,
  setNewSynthetic,
  setActiveImage,
}) {
  const dispatch = useDispatch();
  const dataURLToBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n > 0) {
      n -= 1;
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  };

  const imagesRow1 = [
    "https://artificialsynthetic.s3.eu-central-1.amazonaws.com/avatar_requirements/work1.webp",
    "https://artificialsynthetic.s3.eu-central-1.amazonaws.com/avatar_requirements/work2.webp",
    "https://artificialsynthetic.s3.eu-central-1.amazonaws.com/avatar_requirements/work3.webp",
  ];

  const imagesRow2 = [
    "https://artificialsynthetic.s3.eu-central-1.amazonaws.com/avatar_requirements/50work1.webp",
    "https://artificialsynthetic.s3.eu-central-1.amazonaws.com/avatar_requirements/50work2.webp",
    "https://artificialsynthetic.s3.eu-central-1.amazonaws.com/avatar_requirements/50work3.webp",
  ];

  const imagesRow3 = [
    "https://artificialsynthetic.s3.eu-central-1.amazonaws.com/avatar_requirements/notWork1.webp",
    "https://artificialsynthetic.s3.eu-central-1.amazonaws.com/avatar_requirements/notWork2.webp",
    "https://artificialsynthetic.s3.eu-central-1.amazonaws.com/avatar_requirements/notWork3.webp",
  ];

  const handleCrop = useCallback(
    async (croppedData) => {
      const blob = dataURLToBlob(croppedData);
      const file = new File([blob], "avatar.png", { type: blob.type });

      try {
        const dispatchResult = await dispatch(uploadAvatar(file));
        if (uploadAvatar.fulfilled.match(dispatchResult)) {
          const uploadedAvatarId = dispatchResult.payload.id;

          setNewSynthetic((prevState) => ({
            ...prevState,
            avatarId: uploadedAvatarId,
          }));

          setOpenModal(null);
          setActiveBtnPicture(null);
          setActiveImage(uploadedAvatarId);
          setSelectedAvatar(`data:image/*;base64,${dispatchResult.payload.binaryData}`);
          setCheckDonePicture(true);
        }
      } catch (error) {
        setErrorMessage(error.message || "Failed to upload avatar. Please try again.");
        setOpenErrorWindow(true);
      }
    },
    [
      dispatch,
      setActiveBtnPicture,
      setActiveImage,
      setCheckDonePicture,
      setErrorMessage,
      setNewSynthetic,
      setOpenErrorWindow,
      setOpenModal,
      setSelectedAvatar,
    ]
  );
  return (
    <div>
      <h1>Upload new avatar</h1>
      <div className={localStyle.requirementsBox}>
        <div className={localStyle.requirementsTextBox}>
          <p className={localStyle.requirementsTextHead}>Photo requirements for optimized results:</p>
          <p className={localStyle.requirementsText}>
            <DoneIcon style={{ color: "green" }} />
            <span>Medium shot, frontal facing</span>
          </p>
          <p className={localStyle.requirementsText}>
            <DoneIcon style={{ color: "green" }} />
            Neutral facial expression, closed mouth
          </p>
          <p className={localStyle.requirementsText}>
            <DoneIcon style={{ color: "green" }} />
            File Format: png, jpeg, jpg
          </p>
          <p className={localStyle.requirementsText}>
            <DoneIcon style={{ color: "green" }} />
            Recommended image size is - 512X512 pixels
          </p>
          <p className={localStyle.requirementsText}>
            <DoneIcon style={{ color: "green" }} />
            Good and solid lighting
          </p>
          <p className={localStyle.requirementsText}>
            <DoneIcon style={{ color: "green" }} />
            Size - up to 10Mb
          </p>
          <p className={localStyle.requirementsText}>
            <WarningAmberIcon style={{ color: "#EC4A0A" }} />
            No face occlusions
          </p>
        </div>
        <div>
          <table>
            <tbody className={localStyle.tableReq}>
              <tr>
                <td className={localStyle.textField} colSpan="3">
                  <div style={{ fontWeight: "bold", display: "inline" }}>Recommended avatars: </div>
                  Use clear photos of faces without celebrities. The higher the quality, the greater the chance of a
                  successful creation.
                </td>
              </tr>
              <tr className={localStyle.rowImages}>
                <td>
                  {imagesRow1.map((src, index) => (
                    <div className={localStyle.imageContainer}>
                      <img className={localStyle.exampleImg} key={index} src={src} alt={`${index + 1}`} />
                      <div className={localStyle.icon}>
                        <DoneIcon style={{ color: "green" }} />
                      </div>
                    </div>
                  ))}
                </td>
              </tr>
              <tr>
                <td className={localStyle.textField} colSpan="3">
                  <div style={{ fontWeight: "bold", display: "inline" }}>Avatars at risk of failure: </div>
                  Problems can arise with cartoonish, stylised, low quality or poorly visible faces, which can lead to
                  failure.
                </td>
              </tr>
              <tr className={localStyle.rowImages}>
                <td>
                  {imagesRow2.map((src, index) => (
                    <div className={localStyle.imageContainer}>
                      <img className={localStyle.exampleImg} key={index} src={src} alt={`${index + 1}`} />
                      <div className={localStyle.icon}>
                        <WarningAmberIcon style={{ color: "#EC4A0A" }} />
                      </div>
                    </div>
                  ))}
                </td>
              </tr>
              <tr>
                <td className={localStyle.textField} colSpan="3">
                  <div style={{ fontWeight: "bold", display: "inline" }}>Banned avatars: </div> You cannot use images of celebrities,
                  animals, or photos without a human face. Such images will not be processed.
                </td>
              </tr>
              <tr className={localStyle.rowImages}>
                <td>
                  {imagesRow3.map((src, index) => (
                    <div className={localStyle.imageContainer}>
                      <img className={localStyle.exampleImg} key={index} src={src} alt={`${index + 1}`} />
                      <div className={localStyle.icon}>
                        <CloseIcon style={{ color: "red" }} />
                      </div>
                    </div>
                  ))}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <AvatarCropper onCrop={handleCrop} setActiveBtnPicture={setActiveBtnPicture} />
      <button
        className={`${styles.btn} ${styles.modalUploadCancelBtn}`}
        type="button"
        id="modalUploadCancelBtn"
        onClick={() => {
          setOpenModal(null);
        }}
      >
        Cancel
      </button>
    </div>
  );
}

UploadAvatar.propTypes = {
  setOpenModal: PropTypes.func.isRequired,
  setActiveBtnPicture: PropTypes.func.isRequired,
  setCheckDonePicture: PropTypes.func.isRequired,
  setOpenErrorWindow: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  setSelectedAvatar: PropTypes.func.isRequired,
  setNewSynthetic: PropTypes.func.isRequired,
  setActiveImage: PropTypes.func.isRequired,
};

export default UploadAvatar;
